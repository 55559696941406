import { render, staticRenderFns } from "./shiliaowenda.vue?vue&type=template&id=4d87974c&scoped=true"
import script from "./shiliaowenda.vue?vue&type=script&lang=js"
export * from "./shiliaowenda.vue?vue&type=script&lang=js"
import style0 from "./shiliaowenda.vue?vue&type=style&index=0&id=4d87974c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d87974c",
  null
  
)

export default component.exports