<template>
  <div class="bigDiv">

    <div class="wentikapian" v-for="(item, index) in choice" :key="item.id" v-show="ids == item.id">
      <div class="tit">
        <span class="dangqian">第{{ index + 1 }}题</span>

        <span>/共{{ choice.length }}题</span>
      </div>
      <div v-if="item.type !== 1">
        <div class="wenti">{{ item.question }}</div>
        <div class="xuanze">
          <!-- <div class="xuanxiangActive">男</div> -->
          <div class="xuanxiang" v-for="wenti in item.option" :key="wenti.id" @click="dianjiwent(item,wenti,index)" :class="{
                          xuanxiangActive: isSelected(item, wenti) 
                          
                        }">
            {{ wenti.name }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="wenti">{{ item.question }}</div>
        <div>
          <van-field v-if="item.id===27" v-model="type" placeholder="请输入体重">
            <template #right-icon>
              <div>
                <span>斤</span>
                <span v-if="item.id===26">CM</span>
                <span v-if="item.id===25">岁</span>
              </div>
            </template>
          </van-field>

          <van-field v-if="item.id===26" v-model="type" placeholder="请输入身高">
            <template #right-icon>
              <div>
                <span>CM</span>
              </div>
            </template>
          </van-field>

          <van-field v-if="item.id===25" v-model="type" placeholder="请输入年龄">
            <template #right-icon>
              <div>
                <span>岁</span>
              </div>
            </template>
          </van-field>
        </div>
      </div>
      <div class="flex">
        <div class="xiayit" @click="xiayit(item, index)" v-show="index !== 0">上一题</div>
        <div class="xiayit" @click="cleckAdd(item, index)" v-show="index !== choice.length - 1">下一题</div>
        <div class="xiayit" @click="submitClick" v-show="index === choice.length - 1">完成</div>

      </div>
    </div>

  </div>
</template>
<script>
import { yangshengQuestion, yangshengQuestionAdd, getWechatConfig } from "../../../api/yangsheng";

import { getAccountOauthCodes } from "@/api/components";
import { Toast } from "vant";

export default {
  name: "",
  props: {},
  components: {},
  data () {
    return {
      type: '',
      radios: 1,
      NVwenti: "",
      ids: "",

      choice: [],
      tiankongArr: [],
      gap_filling: [],

      qingqiu: {
        mid: 1718104330069242,
        question: {
          choice: [],
          gap_filling: [
            { id: 28, group: 7, answer: 50 },
            { id: 29, group: 7, answer: 160 },
            { id: 30, group: 7, answer: 120 },
          ],
        },
      },
      selectedBoxes: [],
      selectedAnswers: [],
      group: '',
    };
  },
  created () {
    this.group = this.$route.query.group || 1

    const token = localStorage.getItem('token')
    if (!token) {
      this.getAccountOauthCodes()
    }

    this.getQuestion();
  },
  methods: {
    async getAccountOauthCodes () {
      const data = {
        company_id: 1,
        code: this.$route.query.code
      };
      const res = await getAccountOauthCodes(data)
      localStorage.setItem('token', res.data.data.token)


    },
    getWechatConfig () {
      console.log(wx, 888888888);

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '血管年龄查询', // 分享标题
            link: 'https://kf.hshwhkj.com/shiliaoA', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '三道题测出血管的真实年龄', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '血管年龄查询', // 分享标题
            link: 'https://kf.hshwhkj.com/shiliaoA', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '三道题测出血管的真实年龄', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },
    // 格式化文本

    // 获取问题列表
    async getQuestion () {
      const query = {
        group: this.group,
      };
      const { data } = await yangshengQuestion(query);

      console.log(data.data);
      let mergedArray = [];
      let tiankongArr = []
      for (let key in data.data) {
        if (Array.isArray(data.data[key])) {
          //  mergedArray = mergedArray.concat(data.data[key].filter(item => item.type !== 1));
          mergedArray = mergedArray.concat(data.data[key]);
          tiankongArr = tiankongArr.concat(data.data[key].filter(item => item.type === 1));

        }
      }
      this.tiankongArr = tiankongArr
      const numberOfItems = Object.keys(data.data).length;
      if (numberOfItems > 1) {
        this.choice = mergedArray.reverse()

      } else {
        this.choice = mergedArray
      }
      this.ids = this.choice[0].id;
    },
    // 点击完成后提交
    async submitClick () {

      const unansweredQuestions = this.choice.filter(question => {
        return !this.selectedBoxes.some(item => item.question_id === question.id);
      });

      if (unansweredQuestions.length > 0) {
        console.log(unansweredQuestions);
        this.ids = unansweredQuestions[0].id
        return Toast("请回答所有问题后再继续。");
      } else {
        const query = {
          group: this.group,
          mobile: 18621565934,
          answer: this.selectedBoxes
        }
        const res = query.answer.map(item => {
          if (Array.isArray(item.score)) {
            const sum = item.score.reduce((acc, curr) => acc + curr, 0);
            item.score = sum.toString();
          }
          return item;
        });
        console.log(res);
        const data = await yangshengQuestionAdd(query)
        console.log(data);
        Toast("提交成功")
        this.$router.replace({ path: '/jieguo' })

      }
    },

    // 下一题
    cleckAdd (a, b) {
      console.log(a);
      if (a.id === 27) {
        if (+this.type > 400) {
          return Toast('体重最高400斤!')
        }
      }
      if (a.id === 26) {
        if (+this.type > 250) {
          return Toast('身高最高250CM')
        }
      }
      if (a.id === 25) {
        if (+this.type > 110) {
          return Toast('年龄最高110!')
        }
      }
      if (this.choice[b].type === 1) {
        if (this.type !== '') {
          const index = this.selectedBoxes.findIndex(item => item.question_id === a.id);
          if (index !== -1) {
            // 如果已存在相同 question_id 的数据，则替换掉原有的
            this.$set(this.selectedBoxes, index, {
              question_id: a.id,
              option_id: '',
              answer: [this.type],
              score: 0
            });
          } else {
            // 如果不存在相同 question_id 的数据，则将新数据添加到数组中
            this.selectedBoxes.push({
              question_id: a.id,
              option_id: '',
              answer: [this.type],

              score: 0

            });
          }

          this.type = ''
          console.log(this.selectedBoxes, '888');
        } else {
          return Toast('请输入问题答案!')
        }
      }
      if (b === "" || b === undefined) {
        return (this.ids = this.choice[0].id);
      }
      this.ids = this.choice[b + 1].id;
      scrollTo(0, 0);
    },
    // 上一题
    xiayit (a, b) {
      console.log(b);
      console.log(this.selectedBoxes[b - 1]);
      this.type = this.selectedBoxes[b - 1].answer[0]
      if (b > 0) {
        this.ids = this.choice[b - 1].id;
        scrollTo(0, 0);
      }
    },
    // 单选选择
    dianjiwent (a, b, c) {
      if (a.type === 2) {
        const index = this.selectedBoxes.findIndex(item => item.question_id === a.id);

        if (index !== -1) {
          // 如果已存在相同 question_id 的数据，则替换掉原有的
          this.$set(this.selectedBoxes, index, {
            question_id: a.id,
            option_id: [b.id],
            answer: [b.name],
            score: b.score
          });
        } else {

          // 如果不存在相同 question_id 的数据，则将新数据添加到数组中
          this.selectedBoxes.push({
            question_id: a.id,
            option_id: [b.id],
            answer: [b.name],
            score: b.score

          });
          setTimeout(() => {
            this.ids = this.choice[c + 1].id;
          }, 400);
        }
      } else if (a.type === 3) {
        const index = this.selectedBoxes.findIndex(item => item.question_id === a.id);
        if (index !== -1) {
          // 如果已存在相同 question_id 的数据，则替换掉原有的
          // this.$set(this.selectedBoxes, index, {
          //   question_id: a.id,
          //   option_id: b.id,
          //   answer: b.name,
          //   score: b.score
          // });
          const littleIndex = this.selectedBoxes[index].option_id.findIndex(item => item === b.id);
          if (littleIndex !== -1) {
            // 如果已存在相同 question_id 的数据，则删除掉原有的
            this.selectedBoxes[index].option_id.splice(littleIndex, 1)
            this.selectedBoxes[index].answer.splice(littleIndex, 1)
            this.selectedBoxes[index].score.splice(littleIndex, 1)
            if (this.selectedBoxes[index].option_id.length <= 0) {
              console.log(1);
              this.selectedBoxes.splice(index, 1)
            }
          } else {
            this.selectedBoxes[index].option_id.push(b.id)
            this.selectedBoxes[index].answer.push(b.name)
            this.selectedBoxes[index].score.push(b.score)
          }

        } else {
          // 如果不存在相同 question_id 的数据，则将新数据添加到数组中
          this.selectedBoxes.push({
            question_id: a.id,
            option_id: [b.id],
            answer: [b.name],
            score: [b.score]

          });
        }
      }

    },
    // 单选
    isSelected (question, option) {
      return this.selectedBoxes.some(item => item.question_id === question.id && item.option_id.includes(option.id));
    }
  },
  mounted () {
    this.getWechatConfig()
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  background-color: #f9f9f9;
  min-height: 667px;
  overflow: hidden;
  background-image: url(../../../assets/images/shiliao/beijing22.png);
  background-repeat: no-repeat;
  background-size: cover;
  .wentikapian {
    width: 350px;
    min-height: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 20px auto;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 93px;
    .tit {
      font-size: 14px;
      margin: 20px;
      .dangqian {
        color: #09a27d;
        font-weight: 700;
      }
    }
    .wenti {
      font-size: 20px;
      margin: 20px auto;
      padding-left: 20px;
    }

    .xuanze {
      min-width: 280px;
      margin: 0 auto;
      .xuanxiang {
        width: 330px;
        height: 40px;
        background-color: #f9f9f9;
        margin: 10px auto;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
      }
      .xuanxiangActive {
        width: 330px;
        height: 40px;
        background-color: #ecf8f6;
        color: #06a37e;
        margin: 10px auto;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  .flex {
    width: 300px;
    text-align: center;
    display: flex;
    margin: 0 auto;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    .xiayit {
      width: 120px;
      height: 40px;
      margin: 10px auto;
      border-radius: 50px;
      text-align: center;
      line-height: 40px;
      color: #fff;
      background-color: #02c299;
    }
  }
  .btnFlex {
    width: 300px;
    text-align: center;
    display: flex;
    margin: 0 auto;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */

    div {
      flex: 1;
      width: 120px;
      background-color: #02c299;
      margin: 20px;
      height: 40px;
      line-height: 40px;
      border-radius: 50px;
    }
  }
  .van-field {
    width: 280px !important;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.6) !important;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 500px;
    margin: 0 auto;
    background-color: #f9f9f9;
    min-height: 667px;
    overflow: hidden;
    background-image: url(../../../assets/images/shiliao/beijing22.png);
    background-repeat: no-repeat;
    background-size: cover;
    .wentikapian {
      width: 350px;
      min-height: 20px;
      background-color: rgba(255, 255, 255, 0.6);
      margin: 20px auto;
      border-radius: 10px;
      overflow: hidden;
      margin-top: 93px;
      .tit {
        font-size: 14px;
        margin: 20px;
        .dangqian {
          color: #09a27d;
          font-weight: 700;
        }
      }
      .wenti {
        font-size: 20px;
        margin: 20px auto;
        padding-left: 20px;
      }

      .xuanze {
        min-width: 280px;
        margin: 0 auto;
        .xuanxiang {
          width: 330px;
          height: 40px;
          background-color: #f9f9f9;
          margin: 10px auto;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
        }
        .xuanxiangActive {
          width: 330px;
          height: 40px;
          background-color: #ecf8f6;
          color: #06a37e;
          margin: 10px auto;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .flex {
      width: 300px;
      text-align: center;
      display: flex;
      margin: 0 auto;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      .xiayit {
        width: 120px;
        height: 40px;
        margin: 10px auto;
        border-radius: 50px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        background-color: #02c299;
      }
    }
    .btnFlex {
      width: 300px;
      text-align: center;
      display: flex;
      margin: 0 auto;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */

      div {
        flex: 1;
        width: 120px;
        background-color: #02c299;
        margin: 20px;
        height: 40px;
        line-height: 40px;
        border-radius: 50px;
      }
    }
    .van-field {
      width: 280px !important;
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.6) !important;
    }
  }
}
</style>
